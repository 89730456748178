<template>
  <div>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        temporary
        v-model="setting_dialog"
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-row>
                <v-subheader class="success--text" style="border-bottom: 1px solid var(--v-success-base); width: 100%; height: 40px; padding-top: 6px">
                  Налаштування друку
                </v-subheader>
                <v-col cols="12" md="6">
                  <v-switch hide-details
                            color="grey darken-1"
                            v-model="document_setting.duplicate_on_differance_page"
                            label="2 копії на різні сторінки"
                            class="mt-0"
                            @change="duplicateOnDifferancePageChange"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page page-a4" style="height: auto !important; min-height: 215mm">
      <div class="document-print-form">
        <template v-if="document_setting.duplicate_on_differance_page">
          <template v-for="copy in 2">
            <div :key="`copy-${copy}`">
              <div class="document-table-form">
                <div class="d-flex">
                  <div style="flex: 0 0 40%"></div>
                  <div style="flex: 0 0 16%"></div>
                  <div style="flex: 1;">
                    <div style="line-height: 16px; font-size: 12px">
                      Додаток 3 <br>
                      до Положення про ведення касових операцій у національній валюті в Україні <br>
                      (пункт 26 розділу III)
                    </div>
                    <div style="text-align: right">
                      Типова форма  № КО-2
                    </div>
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div style="flex: 0 0 40%">
                    <div style="border-bottom: 1px solid; text-align: center;">
                      {{ document_data.organization_short_name }}
                    </div>
                    <div style="text-align: center; font-size: 11px">
                      (найменування підприємства/установи/організації)
                    </div>
                  </div>
                  <div style="flex: 1; padding-left: 10px">
                    <div>
                      Ідентифікаційний код в Єдиному державному реєстрі підприємств <br>
                    </div>
                    <div class="d-flex">
                      <div style="flex: 0 0 140px">
                        та організацій України
                      </div>
                      <div style="flex: 1; border-bottom: 1px solid; text-align: center">
                        {{ document_data.organization_code }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2 my-0" style="text-align: center; font-size: 20px; font-weight: bold">
                  Видатковий касовий ордер
                </div>
                <div style="text-align: center">
                  від {{ formatDate(document_data.doc_date, 'DD MMMM YYYY') }} року
                </div>
                <div>
                  <table style="border-collapse: collapse; width: 100%">
                    <thead>
                    <tr>
                      <th style="width: 50px">№ з/п</th>
                      <th style="width: 80px">Номер документа</th>
                      <th style="width: 96px">Дата складання</th>
                      <th style="width: 130px">
                        Кореспондуючий рахунок, субрахунок
                      </th>
                      <th style="width: 86px">
                        Код аналітичного рахунку
                      </th>
                      <th>Сума</th>
                      <th style="width: 106px">Код цільового призначення</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>1</td>
                      <td>2</td>
                      <td>3</td>
                      <td>4</td>
                      <td>5</td>
                      <td>6</td>
                      <td>7</td>
                    </tr>
                    <tr v-for="(row, idx) in document_data.table"
                        :key="idx"
                        style="font-weight: bold">
                      <td>{{ idx + 1 }}</td>
                      <td>{{ document_data.order_number }}</td>
                      <td>{{ document_data.doc_date | formatDate }}</td>
                      <td>{{ row.code }}</td>
                      <td></td>
                      <td>{{ row.sum | formatToFixed | formatNumber }} грн.</td>
                      <td></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mt-2">
                  <div style="display: flex;">
                    <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                      Видати
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                      {{ document_data.get_from }}
                    </div>
                  </div>
                  <div style="display: flex;">
                    <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">

                    </div>
                    <div style="flex: 1; text-align: center; font-size: 12px">
                      (прізвище, ім'я, по батькові)
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <div style="display: flex;">
                    <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                      Підстава
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                      {{ document_data.reason }}
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <div style="display: flex;">
                    <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                      Сума
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                      {{ document_data.converted_number }}
                    </div>
                  </div>
                  <div style="display: flex;">
                    <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">

                    </div>
                    <div style="flex: 1; text-align: center; font-size: 12px">
                      (словами)
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <div style="display: flex;">
                    <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                      Додаток
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                      {{ document_data.additional }}
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <div style="display: flex;">
                    <div style="flex: 0 0 42%;">
                      <div class="d-flex">
                        <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                          Керівник
                        </div>
                        <div style="flex: 1; border-bottom: 1px solid black">
                          {{ director }}
                        </div>
                      </div>
                    </div>
                    <div style="flex: 1;">
                      <div class="d-flex">
                        <div style="flex: 0 0 160px; font-weight: bold; font-size: 13px; padding-left: 10px">
                          Головний бухгалтер
                        </div>
                        <div style="flex: 1; border-bottom: 1px solid black">
                          {{ chief_accountant }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <div style="display: flex;">
                    <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                      Одержав
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black; text-align: right">
                      грн. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; коп
                    </div>
                  </div>
                  <div style="display: flex;">
                    <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">

                    </div>
                    <div style="flex: 1; text-align: center; font-size: 12px">
                      (словами)
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <div style="display: flex;">
                    <div style="flex: 0 0 60%">
                      "_____" __________________ 20____ року
                    </div>
                    <div style="flex: 1">
                      <div class="d-flex">
                        <div style="flex: 0 0 140px">
                          Підпис одержувача
                        </div>
                        <div style="flex: 1; border-bottom: 1px solid black"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <div style="border-bottom: 1px solid black; height: 21px">
                    {{ document_data.document_person_identity }}
                  </div>
                  <div style="text-align: center">
                    (найменування, номер, дата та мiсце видачi документа, який засвідчує особу одержувача)
                  </div>
                </div>
                <div class="mt-2" style="width: 50%">
                  <div style="display: flex;">
                    <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                      Видав касир
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                    </div>
                  </div>
                  <div style="display: flex;">
                    <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">

                    </div>
                    <div style="flex: 1; text-align: center; font-size: 12px">
                      (підпис, прізвище, ініціали)
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="copy === 1">
                <p class="document-separator" :key="`sep-${copy}`">&nbsp;</p>
              </template>
            </div>
          </template>
        </template>
        <template v-if="!document_setting.duplicate_on_differance_page && !document_setting.duplicate_on_one_page">
          <div class="document-table-form">
            <div class="d-flex">
              <div style="flex: 0 0 40%"></div>
              <div style="flex: 0 0 16%"></div>
              <div style="flex: 1;">
                <div style="line-height: 16px; font-size: 12px">
                  Додаток 3 <br>
                  до Положення про ведення касових операцій у національній валюті в Україні <br>
                  (пункт 26 розділу III)
                </div>
                <div style="text-align: right">
                  Типова форма  № КО-2
                </div>
              </div>
            </div>
            <div class="d-flex mt-2">
              <div style="flex: 0 0 40%">
                  <div style="border-bottom: 1px solid; text-align: center;">
                    {{ document_data.organization_short_name }}
                  </div>
                  <div style="text-align: center; font-size: 11px">
                    (найменування підприємства/установи/організації)
                  </div>
              </div>
              <div style="flex: 1; padding-left: 10px">
                <div>
                  Ідентифікаційний код в Єдиному державному реєстрі підприємств <br>
                </div>
                <div class="d-flex">
                  <div style="flex: 0 0 140px">
                    та організацій України
                  </div>
                  <div style="flex: 1; border-bottom: 1px solid; text-align: center">
                    {{ document_data.organization_code }}
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2 my-0" style="text-align: center; font-size: 20px; font-weight: bold">
              Видатковий касовий ордер
            </div>
            <div style="text-align: center">
              від {{ formatDate(document_data.doc_date, 'DD MMMM YYYY') }} року
            </div>
            <div>
              <table style="border-collapse: collapse; width: 100%">
                <thead>
                  <tr>
                    <th style="width: 50px">№ з/п</th>
                    <th style="width: 80px">Номер документа</th>
                    <th style="width: 96px">Дата складання</th>
                    <th style="width: 130px">
                      Кореспондуючий рахунок, субрахунок
                    </th>
                    <th style="width: 86px">
                      Код аналітичного рахунку
                    </th>
                    <th>Сума</th>
                    <th style="width: 106px">Код цільового призначення</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                  <td>5</td>
                  <td>6</td>
                  <td>7</td>
                </tr>
                <tr v-for="(row, idx) in document_data.table"
                    :key="idx"
                    style="font-weight: bold">
                  <td>{{ idx + 1 }}</td>
                  <td>{{ document_data.order_number }}</td>
                  <td>{{ document_data.doc_date | formatDate }}</td>
                  <td>{{ row.code }}</td>
                  <td></td>
                  <td>{{ row.sum | formatToFixed | formatNumber }} грн.</td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-2">
              <div style="display: flex;">
                <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                  Видати
                </div>
                <div style="flex: 1; border-bottom: 1px solid black">
                  {{ document_data.get_from }}
                </div>
              </div>
              <div style="display: flex;">
                <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">

                </div>
                <div style="flex: 1; text-align: center; font-size: 12px">
                  (прізвище, ім'я, по батькові)
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div style="display: flex;">
                <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                  Підстава
                </div>
                <div style="flex: 1; border-bottom: 1px solid black">
                  {{ document_data.reason }}
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div style="display: flex;">
                <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                  Сума
                </div>
                <div style="flex: 1; border-bottom: 1px solid black">
                  {{ document_data.converted_number }}
                </div>
              </div>
              <div style="display: flex;">
                <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">

                </div>
                <div style="flex: 1; text-align: center; font-size: 12px">
                  (словами)
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div style="display: flex;">
                <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                  Додаток
                </div>
                <div style="flex: 1; border-bottom: 1px solid black">
                  {{ document_data.additional }}
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div style="display: flex;">
                <div style="flex: 0 0 42%;">
                  <div class="d-flex">
                    <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                      Керівник
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                      {{ director }}
                    </div>
                  </div>
                </div>
                <div style="flex: 1;">
                  <div class="d-flex">
                    <div style="flex: 0 0 160px; font-weight: bold; font-size: 13px; padding-left: 10px">
                      Головний бухгалтер
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black">
                      {{ chief_accountant }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div style="display: flex;">
                <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                  Одержав
                </div>
                <div style="flex: 1; border-bottom: 1px solid black; text-align: right">
                  грн. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; коп
                </div>
              </div>
              <div style="display: flex;">
                <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">

                </div>
                <div style="flex: 1; text-align: center; font-size: 12px">
                  (словами)
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div style="display: flex;">
                <div style="flex: 0 0 60%">
                  "_____" __________________ 20____ року
                </div>
                <div style="flex: 1">
                  <div class="d-flex">
                    <div style="flex: 0 0 140px">
                      Підпис одержувача
                    </div>
                    <div style="flex: 1; border-bottom: 1px solid black"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div style="border-bottom: 1px solid black; height: 21px">
                {{ document_data.document_person_identity }}
              </div>
              <div style="text-align: center">
                (найменування, номер, дата та мiсце видачi документа, який засвідчує особу одержувача)
              </div>
            </div>
            <div class="mt-2" style="width: 50%">
              <div style="display: flex;">
                <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">
                  Видав касир
                </div>
                <div style="flex: 1; border-bottom: 1px solid black">
                </div>
              </div>
              <div style="display: flex;">
                <div style="flex: 0 0 100px; font-weight: bold; font-size: 13px">

                </div>
                <div style="flex: 1; text-align: center; font-size: 12px">
                  (підпис, прізвище, ініціали)
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {convertNumberToLetterString} from "@/utils/accounting";
import documentPrintAPI from "@/utils/axios/accounting/documents/cash_order_outcome"
import {formatDate} from "@/filters";
import {endOfMonth} from "@/utils/icons";

export default {
  name: "cash_order_outcome_v1",
  props: {
    organization: {
      type: Object,
      default() {
        return {}
      }
    },
    print_data: {
      type: Object,
      default() {
        return {}
      }
    },
    document_id: {
      type: Number,
      default: null
    },
    global_settings: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    },
    chief_accountant() {
      return (((this.print_data.response_people || []).find(i => i.category === 'chief_accountant')) || {}).short_name || ''
    },
    director() {
      return (((this.print_data.response_people || []).find(i => i.category === 'director')) || {}).short_name || ''
    }
  },
  data() {
    return {
      setting_dialog: this.global_settings,
      settings_watcher: null,
      document_setting: {
        duplicate_on_differance_page: false
      },
      document_data: {}
    }
  },
  methods: {
    duplicateOnOnePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_differance_page = false
      }
    },
    duplicateOnDifferancePageChange(payload) {
      if (payload) {
        this.document_setting.duplicate_on_one_page = false
      }
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    formatDate,
    getResponsePerson(table, category, field='short_name') {
      if (table === 'organization') {
        return ((this.print_data?.response_people || []).find(i => i.category === category) || {})?.[field] || ''
      } else {
        return (this.document_data?.contractor_print_data || {})?.response_persons?.[category]?.[field] || ''
      }
    },
    getBalanceTitle() {
      const end_month = endOfMonth(this.document_data.month)
      const date = this.document_setting.balance_end ? end_month : this.document_data.month
      const balance = this.document_setting.balance_end
          ? this.document_data.balance_end : this.document_data.balance_start
      let balance_title = ''
      if (balance < 0) {
        balance_title = 'Переплата станом на'
      } else {
        balance_title = 'Заборгованість станом на'
      }

      return `${balance_title} ${formatDate(date, 'DD.MM.YYYY')}`
    },
    convertNumberToString(payload) {
      return convertNumberToLetterString(payload)
    }
  },
  watch: {
    modal: {
      immediate: true,
      handler(payload) {
        if (payload) {
          documentPrintAPI.get_document_for_print(this.document_id)
              .then(response => response.data)
              .then(data => this.document_data = data)
        } else {
          this.document_data = {}
        }

      }
    }
  },
  created() {
    this.watch_settings()
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
.document-print-form {
  width: 100%;
  font-family: Arial, Calibri, serif;
  padding: 4px 12px;
  font-size: 13px;

  .document-table-form {
    width: 100%;
    border-collapse: collapse;
    table {
      border-collapse: collapse;
    }

    thead {
      tr {
        border: 1px solid grey;

        th {
          border: 1px solid grey;
          padding: 2px 6px;
          font-size: .74rem;
          font-weight: normal;
          line-height: 1.05rem;
        }
      }
    }

    tbody {
      tr {
        border: 1px solid grey;

        td {
          border: 1px solid grey;
          padding: 2px 6px;
          font-size: .84rem;
          line-height: .94rem;
          text-align: center;
        }
      }
    }
  }

}
</style>